import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  ConsultationState,
  ConsultationLocaleState,
  ConsultationLocaleMutations,
  ConsultationLocaleActions,
  ConsultationLocaleGetters,
  ConsultationForm,
} from "@/store/consultation/types";

const defaultConsultationForm: ConsultationForm = {
  cd_especialidade: null,
  cd_especialista: null,
  cd_estabelecimento: null,
  cd_pessoa_fisica: null,
  nr_valor: "0",
  schedule: null,
  date: null,
  time: null,
};

const state: ConsultationLocaleState = {
  form: { ...defaultConsultationForm },
};

const getters: GetterTree<ConsultationState, RootState> & ConsultationLocaleGetters = {};

const mutations: MutationTree<ConsultationState> & ConsultationLocaleMutations = {
  setConsultationForm(state, { key, value }) {
    state.form[key] = value?.toString() || null;
  },
  resetConsultationForm(state) {
    state.form = { ...defaultConsultationForm };
  },
};

const actions: ActionTree<ConsultationState, RootState> & ConsultationLocaleActions = {};

const modules: ModuleTree<RootState> = {};

export const ConsultationStore = { state, getters, mutations, actions, modules };
