import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { store } from "@/store";

function saveCampaignRouteQuery(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (!to.query.c && from.query.c && !localStorage?.getItem("CAMPAIGN"))
    next({ ...to, query: { ...(to.query || {}), c: from.query.c } });
  else next();
}

function checkAuth(to: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresNotLogged = to.matched.some((record) => record.meta.requiresNotLogged);

  if (requiresAuth) {
    if (store.getters.isLogged) next();
    else next({ query: { ...to.query, lf: "true" } });
  } else {
    if (store.getters.isLogged && requiresNotLogged) next({ name: "root" });
    else next();
  }
}

export { saveCampaignRouteQuery, checkAuth };
