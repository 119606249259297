import { RouteRecordRaw } from "vue-router";

const requiresNotLoggedRoutes: RouteRecordRaw[] = [
  {
    path: "/esqueci-minha-senha",
    name: "forgot-password",
    meta: { requiresNotLogged: true },
    component: () => import("@/views/forgot-password/forgot.vue"),
  },
  {
    path: "/forgotmypassword",
    meta: { requiresNotLogged: true },
    redirect: (to) => ({ name: "recover-password", query: to.query }),
  },
  {
    path: "/redefinir-senha",
    name: "recover-password",
    meta: { requiresNotLogged: true },
    component: () => import("@/views/forgot-password/recover.vue"),
    beforeEnter: (to) => (!to.query.token ? { name: "root" } : true),
  },
];

const requiresNotLoggedModalRoutes: RouteRecordRaw[] = [
  {
    path: "/entrar",
    name: "login",
    meta: { requiresNotLogged: true },
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/criar-conta",
    name: "register",
    meta: { requiresNotLogged: true },
    redirect: { name: "register-login" },
  },
  {
    path: "/criar-conta/login",
    name: "register-login",
    meta: { requiresNotLogged: true },
    component: () => import("@/views/register/login.vue"),
  },
  {
    path: "/criar-conta/dados-pessoais",
    name: "register-personal",
    meta: { requiresNotLogged: true },
    component: () => import("@/views/register/personal.vue"),
  },
  {
    path: "/criar-conta/endereco",
    name: "register-address",
    meta: { requiresNotLogged: true },
    component: () => import("@/views/register/address.vue"),
  },
  {
    path: "/criar-conta/finalizar",
    name: "register-finish",
    meta: { requiresNotLogged: true },
    component: () => import("@/views/register/register.vue"),
  },
];

export { requiresNotLoggedRoutes, requiresNotLoggedModalRoutes };
