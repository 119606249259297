import { UserPatient } from "@/store/user/types";

export function hasUserAddress(user: UserPatient) {
  return user.nr_cep && user.nm_bairro && user.nm_rua && user.nr_numero && user.cd_cidade?.id;
}

const bankInfos = {
  nubank: {
    name: "Nubank",
    gradient: "177.23deg, #9C2CF3 -13.49%, #3A49F9 109.75%",
    bins: ["516220", "516230", "516292", "523421", "537678", "550209", "554865"],
  },
  inter: {
    name: "Inter",
    gradient: "167.6deg, #ffa500 -9.78%, #ffae00 107.04%",
    bins: ["545371", "549519", "559529"],
  },
  original: {
    name: "Original",
    gradient: "167.6deg, #00AC8D -9.78%, #00876F 107.04%",
    bins: ["526784", "528400", "534540", "534543", "537110"],
  },
  santander: {
    name: "Santander",
    gradient: "167.6deg, #E42C66 -9.78%, #F55B46 107.04%",
    bins: [
      ...["407302", "407303", "407392", "407434", "407435", "409308", "409309", "410055"],
      ...["410863", "411050", "411085", "411833", "411834", "415703", "419137", "419138"],
      ...["419139", "419140", "419141", "419142", "419143", "419144", "419145", "419146"],
      ...["419147", "419148", "419190", "419618", "419619", "419620", "419622", "419623"],
      ...["419624", "419625", "419627", "419628", "419630", "419631", "422048", "422061"],
      ...["423661", "423808", "423809", "424305", "424306", "425470", "425850", "430963"],
      ...["439252", "439253", "439254", "441061", "441062", "441064", "441065", "441066"],
      ...["441067", "441120", "441122", "441123", "441524", "441536", "445993", "446193"],
      ...["446194", "448798", "448799", "450583", "451296", "451736", "451799", "455153"],
      ...["455164", "455165", "455166", "455167", "455168", "455169", "457937", "457938"],
      ...["459091", "459092", "459093", "459094", "459945", "459946", "459947", "459948"],
      ...["460448", "463312", "463313", "463314", "463315", "467112", "467113", "467114"],
      ...["469856", "469857", "474507", "474511", "474512", "477043", "479392", "480629"],
      ...["480630", "481135", "481140", "485619", "485657", "489389", "489426", "489427"],
      ...["491314", "491315", "491316", "491513", "491514", "491623", "491674", "491675"],
      ...["491676", "491696", "491944", "492400", "492401", "492402", "492403", "492404"],
      ...["492405", "492460", "499918", "499919", "499984", "410861", "515590", "517756"],
      ...["518148", "518294", "518295", "518296", "518311", "518312", "518313", "518768"],
      ...["519339", "520132", "520184", "520185", "521179", "521180", "522839", "522840"],
      ...["526128", "526595", "528052", "531454", "531455", "531488", "531654", "531699"],
      ...["531714", "531715", "531727", "534581", "540105", "540106", "541100", "541740"],
      ...["542353", "542688", "542820", "542836", "543658", "543660", "544165", "544412"],
      ...["544731", "545500", "545805", "548648", "548649", "549518", "550154", "552144"],
      ...["552693", "552943", "553457", "553458", "554389", "554612", "556035", "542292"],
    ],
  },
  itau: {
    name: "Itau",
    gradient: "167.6deg, #FFEF00 -9.78%, #FFBF00 107.04%",
    bins: [
      ...["222661", "222662", "222663", "222664", "510089", "510426", "512047", "512059", "512099"],
      ...["512207", "512215", "512363", "512374", "512461", "512658", "512671", "512673", "513728"],
      ...["513731", "514079", "514090", "514233", "514868", "514897", "514898", "514945", "515640"],
      ...["515743", "515765", "515802", "515836", "516070", "516164", "516275", "516283", "516291"],
      ...["516306", "517191", "517701", "517703", "517787", "517858", "517914", "517967", "518020"],
      ...["518032", "518054", "518063", "518138", "518218", "518306", "518307", "518326", "518327"],
      ...["518328", "518491", "518534", "518950", "518967", "520196", "520199", "520400", "520401"],
      ...["520402", "520403", "520404", "520405", "520621", "520977", "521039", "521042", "521043"],
      ...["521397", "521880", "522015", "522027", "522446", "522949", "522979", "523284", "523425"],
      ...["523431", "523432", "523791", "524003", "524314", "524474", "524703", "524820", "525320"],
      ...["525496", "525661", "525662", "525663", "525664", "525692", "525695", "525718", "526762"],
      ...["526769", "526788", "526863", "526892", "526893", "526944", "527010", "527018", "527036"],
      ...["527405", "527407", "527425", "527430", "527467", "527468", "527495", "527496", "527497"],
      ...["527532", "527533", "527538", "527539", "527540", "527541", "527543", "527544", "527545"],
      ...["527616", "527887", "527889", "528196", "528392", "528860", "528941", "529285", "529323"],
      ...["529416", "529417", "529418", "530038", "530049", "530073", "530148", "530452", "530599"],
      ...["530771", "530780", "530851", "530994", "530996", "531087", "531249", "531377", "531500"],
      ...["531502", "531503", "531661", "531671", "531681", "531705", "532910", "532942", "534249"],
      ...["534361", "534447", "534448", "534503", "534513", "534516", "534562", "534571", "534627"],
      ...["534915", "534916", "535081", "535085", "535088", "535091", "535094", "535097", "535106"],
      ...["535822", "535841", "535850", "535858", "535863", "535867", "535871", "536143", "536352"],
      ...["536367", "536371", "536391", "536452", "536458", "536467", "536471", "536477", "536480"],
      ...["536481", "536482", "536491", "536552", "536558", "536567", "536571", "536577", "536580"],
      ...["536581", "536582", "536591", "536804", "539011", "539012", "539021", "539022", "539028"],
      ...["539029", "539039", "539040", "539058", "539059", "539060", "539062", "539063", "539064"],
      ...["539066", "539067", "539068", "539070", "539072", "539073", "539074", "539077", "539078"],
      ...["539083", "539084", "539085", "539088", "539090", "539091", "540508", "540517", "540530"],
      ...["540631", "540645", "540646", "540755", "540760", "541006", "541187", "541426", "541555"],
      ...["541759", "541886", "542477", "542510", "542622", "542701", "542711", "542712", "542974"],
      ...["542976", "542982", "543051", "543095", "543391", "543559", "543690", "543744", "543869"],
      ...["543927", "543960", "543971", "544166", "544169", "544199", "544265", "544276", "544293"],
      ...["544296", "544300", "544328", "544386", "544442", "544507", "544522", "544540", "544546"],
      ...["544547", "544554", "544560", "544570", "544599", "544600", "544626", "544654", "544665"],
      ...["544672", "544673", "544753", "544828", "544829", "544831", "544833", "544839", "544841"],
      ...["544859", "544860", "544862", "544863", "544864", "544867", "544872", "544874", "544881"],
      ...["544883", "544890", "544891", "545011", "545349", "545368", "545462", "545463", "545553"],
      ...["545653", "545670", "545719", "545768", "545780", "545810", "545823", "545832", "545850"],
      ...["545957", "545959", "545967", "546003", "546012", "546017", "546056", "546279", "546282"],
      ...["546337", "546338", "546460", "546461", "546559", "546744", "546754", "546815", "546841"],
      ...["547076", "547468", "547483", "547674", "548295", "548296", "548305", "548337", "548338"],
      ...["548364", "548474", "548514", "548625", "548708", "548721", "548722", "548723", "548724"],
      ...["548731", "548739", "548802", "548839", "548929", "548961", "548970", "548976", "548984"],
      ...["548985", "549090", "549167", "549221", "549296", "549319", "549328", "549329", "549332"],
      ...["549339", "549341", "549358", "549359", "549363", "549367", "549368", "549372", "549378"],
      ...["549383", "549390", "549391", "549585", "552070", "552072", "552128", "552236", "552244"],
      ...["552297", "552317", "552544", "552640", "552697", "552914", "553034", "553624", "553636"],
      ...["553637", "553647", "553665", "553666", "554281", "554282", "554309", "554480", "554497"],
      ...["554587", "554613", "554634", "554722", "554774", "554775", "554776", "554903", "554917"],
      ...["554953", "554954", "555428", "556023", "556024", "556026", "556615", "556616", "556670"],
      ...["556673", "557771", "557797", "558297", "558303", "558383", "558394", "558419", "558645"],
      ...["559275", "400217", "400225", "400234", "400235", "400247", "400248", "400252", "400253"],
      ...["400268", "400439", "400634", "400635", "400647", "400652", "400653", "400654", "401342"],
      ...["401692", "402773", "402774", "403217", "403225", "403230", "403235", "403246", "403247"],
      ...["403254", "403290", "405773", "405774", "405775", "405776", "405777", "405778", "405779"],
      ...["405780", "405880", "405885", "405966", "405967", "405968", "406871", "407409", "407414"],
      ...["407433", "407505", "407600", "411049", "411195", "411196", "411766", "411767", "411860"],
      ...["411861", "412723", "413408", "413409", "413410", "413411", "414504", "414505", "414506"],
      ...["415646", "416069", "416094", "416914", "417957", "417958", "417987", "417988", "418043"],
      ...["418044", "418668", "419590", "419591", "419592", "420180", "420181", "420182", "421309"],
      ...["421470", "421471", "421507", "421508", "421644", "421843", "421844", "421845", "421847"],
      ...["421848", "421862", "422004", "422005", "422006", "422007", "422008", "422100", "422101"],
      ...["422102", "422103", "422185", "422200", "422201", "422202", "422203", "422628", "422731"],
      ...["426031", "432943", "434101", "438903", "439354", "439388", "439389", "439390", "439478"],
      ...["439479", "439480", "440132", "441030", "441349", "444054", "444214", "444215", "444216"],
      ...["444217", "444218", "445490", "445491", "445492", "445497", "445498", "446305", "446865"],
      ...["449773", "450321", "450323", "450419", "451300", "451301", "451327", "451355", "451356"],
      ...["451363", "452407", "452991", "452992", "454367", "454368", "454427", "456137", "456347"],
      ...["456389", "456401", "456890", "457020", "457548", "457943", "459020", "459023", "459077"],
      ...["459078", "459079", "459080", "459313", "459314", "459315", "459316", "459420", "459421"],
      ...["459450", "459451", "459452", "459454", "459455", "459456", "459457", "459458", "459459"],
      ...["459460", "459461", "459462", "459463", "460034", "460035", "460036", "460037", "460038"],
      ...["463291", "463292", "463293", "463294", "463295", "463296", "463297", "463298", "463299"],
      ...["464294", "464295", "464296", "464297", "464298", "464299", "464300", "464301", "465769"],
      ...["467082", "467083", "467085", "467100", "467101", "467150", "467151", "467152", "467153"],
      ...["469865", "469866", "469867", "469868", "470598", "472305", "472663", "472664", "472665"],
      ...["472666", "472667", "472668", "472669", "472955", "472956", "472957", "473273", "473274"],
      ...["473275", "474359", "474536", "474537", "474538", "474547", "474548", "474549", "474565"],
      ...["476602", "477128", "477129", "477144", "477145", "477175", "477176", "477249", "478307"],
      ...["478308", "478309", "478310", "479398", "479407", "479408", "482405", "482447", "482448"],
      ...["482476", "482477", "482478", "482479", "482480", "482481", "482482", "483085", "483096"],
      ...["483097", "483098", "483150", "483151", "485103", "485104", "485486", "486641", "486654"],
      ...["486692", "489203", "489204", "489391", "489392", "489399", "489400", "489414", "489423"],
      ...["489430", "489451", "489902", "489962", "490105", "490106", "490119", "490144", "490172"],
      ...["491414", "491415", "491416", "491446", "491447", "491448", "493186", "493490", "496048"],
      ...["496049", "498515", "498530", "498553", "498554", "498555", "498556"],
    ],
  },
};

function scrollIntoView(query: string) {
  setTimeout(() => {
    const section = document.querySelector(query);
    section?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, 200);
}

function showDevelopmentAlert() {
  alert("Em desenvolvimento!");
}

function getCardFlag(cardNumber: string) {
  const handledCardNumbers = cardNumber.replace(/[^0-9]+/g, "");

  const flags = {
    visa: { name: "Visa", regex: /^4[0-9]{12}(?:[0-9]{3})/ },
    mastercard: { name: "MasterCard", regex: /^5[1-5][0-9]{14}/ },
    // diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    // amex: /^3[47][0-9]{13}/,
    // discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    // hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    // elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    // jcb: /^(?:2131|1800|35\d{3})\d{11}/,
    // aura: /^(5078\d{2})(\d{2})(\d{11})$/,
  };

  const flagKey = (Object.keys(flags) as Array<keyof typeof flags>).find((flag) =>
    flags[flag].regex.test(handledCardNumbers)
  );

  return flagKey ? { ...flags[flagKey], key: flagKey } : null;
}

function getCardBank(cardNumbers: string) {
  const handledCardNumbers = cardNumbers.replace(/[^0-9]+/g, "");
  const firstCardNumbers = handledCardNumbers.slice(0, 6);

  const bankKey = (Object.keys(bankInfos) as Array<keyof typeof bankInfos>).find((bankKey) =>
    bankInfos[bankKey].bins.includes(firstCardNumbers)
  );
  const bankFlag = getCardFlag(handledCardNumbers);

  return { bank: bankKey ? { ...bankInfos[bankKey], key: bankKey } : null, flag: bankFlag };
}

export { scrollIntoView, showDevelopmentAlert, getCardBank, getCardFlag };
