import obmed, { ObmedCancelRequest } from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { PaginationParams, PaginationResponse, RootState } from "@/store/types";
import {
  Specialist,
  SpecialistState,
  SpecialistLocaleState,
  SpecialistLocaleMutations,
  SpecialistLocaleActions,
  SpecialistLocaleGetters,
} from "@/store/specialist/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "especialista-com-suas-especialidades/",
  byID: (specialistID: number | string) => `especialista/${specialistID}/`,
  groupedByID: (specialtyID: number, establishmentID: number) =>
    `especialista-agrupado-por-data/?cd_especialidade=${specialtyID}&ie_telemedicina=false&cd_estabelecimento=${establishmentID}`,
  dateByID: (specialtyID: number, establishmentID: number) =>
    `especialista-por-especialidade-e-estabelecimento/?cd_especialidade=${specialtyID}&ie_telemedicina=false&cd_estabelecimento=${establishmentID}`,
};

const cancelRequests: { [key: string]: ObmedCancelRequest | null } = {
  getAll: null,
};

const state: SpecialistLocaleState = {
  current: null,
  list: [],
  dateList: {},
  dates: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 12 },
};

const getters: GetterTree<SpecialistState, RootState> & SpecialistLocaleGetters = {};

const mutations: MutationTree<SpecialistState> & SpecialistLocaleMutations = {
  setCurrentSpecialist(state, specialist) {
    state.current = specialist;
  },
  setAllSpecialist(state, specialistList) {
    state.list = specialistList;
  },
  setAllSpecialistByDate(state, specialistList) {
    state.dateList = specialistList;
  },
  setAllSpecialistDate(state, dates) {
    state.dates = dates;
  },
  setSpecialistPagination(state, pagination) {
    state.pagination = pagination;
  },
  setSpecialistPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<SpecialistState, RootState> & SpecialistLocaleActions = {
  async getSpecialist({ commit, dispatch }, { _id, setCurrentSpecialist = true }) {
    try {
      const response = await obmed.get<Specialist>({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });

      if (setCurrentSpecialist) commit("setCurrentSpecialist", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllSpecialist(
    { commit, dispatch },
    { nm_especialista, ie_sexo, ie_ordem_alfabetica, ie_telemedicina = false, isPaginated = true } = {}
  ) {
    try {
      if (cancelRequests.getAll) cancelRequests.getAll.cancel();
      cancelRequests.getAll = obmed.getCancelToken();

      const pagination: PaginationParams = {
        page: state.pagination.page,
        per_page: state.pagination.perPage,
        paginacao: Boolean(isPaginated),
      };

      const response = await obmed.get<PaginationResponse<Specialist>>({
        entity: endpoints.root,
        config: {
          query: {
            ...pagination,
            nm_especialista,
            ie_sexo,
            ie_ordem_alfabetica,
            ie_telemedicina: String(ie_telemedicina),
          },
          cancelToken: cancelRequests.getAll?.token,
          baseURL: VUE_APP_API_URL,
        },
      });
      commit("setAllSpecialist", response.data.result);
      commit("setSpecialistPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllSpecialistByDate({ rootState, commit, dispatch }, payload) {
    try {
      const response = await obmed.get<{ [key: string]: Specialist[] }>({
        entity: endpoints.groupedByID(
          Number(payload.specialtyID || rootState.specialty.current?.id),
          Number(payload.establishmentID || rootState.establishment.current?.id)
        ),
        config: {
          query: {
            nm_especialista: payload.nm_especialista,
            dt_consulta: payload.date,
            ie_sexo: payload.ie_sexo,
            ie_ordem_alfabetica: payload.ie_ordem_alfabetica,
          },
          baseURL: VUE_APP_API_URL,
        },
      });
      commit("setAllSpecialistByDate", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllSpecialistDate({ rootState, commit, dispatch }, payload) {
    try {
      const response = await obmed.get<string[]>({
        entity: endpoints.dateByID(
          Number(payload.specialtyID || rootState.specialty.current?.id),
          Number(payload.establishmentID || rootState.establishment.current?.id)
        ),
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setAllSpecialistDate", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const SpecialistStore = { state, getters, modules, mutations, actions };
