import { createStore, ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";
import { UserStore } from "@/store/user";

import { ObmedError } from "@/services/obmed/types";
import { ViaCEPError } from "@/services/viacep/types";

import { RootState, RootLocalState, RootLocalActions, RootLocalMutations, RootLocaleGetters } from "@/store/types";
import { LocalityStore } from "@/store/locality";
import { FamilyGroupStore } from "@/store/family-group";
import { CreditCardStore } from "@/store/credit-card";
import { SpecialtyStore } from "@/store/specialty";
import { EstablishmentStore } from "@/store/establishment";
import { SpecialistStore } from "@/store/specialist";
import { CartStore } from "@/store/cart";
import { ProcedureStore } from "@/store/procedure";
import { ExamStore } from "@/store/exam";
import { BudgetStore } from "@/store/budget";
import { ConsultationStore } from "@/store/consultation";
import { ServicePackStore } from "@/store/service-pack";

const { NODE_ENV } = process.env;

const state: RootLocalState = {
  toast: { messages: [] },
};

const getters: GetterTree<RootState, RootState> & RootLocaleGetters = {
  hasToast(state) {
    return Boolean(state.toast.messages.length);
  },
};

const mutations: MutationTree<RootState> & RootLocalMutations = {
  setToast(state, items) {
    state.toast.messages = items.map((item) => {
      let life = (item.detail || item.summary).length * 75;
      if (life < 2000) life = 2000;

      return {
        summary: item.summary,
        detail: item.detail ?? "",
        severity: item.severity,
        life: item.life ?? life,
        closable: item.closable ?? false,
      };
    });
  },
  addToast(state, item) {
    let life = (item.detail || item.summary).length * 80;
    if (life < 2000) life = 3000;

    state.toast.messages.push({
      summary: item.summary,
      detail: item.detail ?? "",
      severity: item.severity,
      life: item.life ?? life,
      closable: item.closable ?? false,
    });
  },
};

const actions: ActionTree<RootState, RootState> & RootLocalActions = {
  async handleError({ commit }, error) {
    if (error.isObmedError) {
      const obmedError: ObmedError = error;

      if (!obmedError.isCancel) commit("setToast", obmedError.messages);
    } else if (error.isViaCEPError) {
      const viaCEPError: ViaCEPError = error;
      if (!viaCEPError.isCancel) commit("setToast", viaCEPError.messages);
    } else {
      if (NODE_ENV === "development") console.error(error);

      Promise.reject("Erro inesperado");
    }
  },
};

const modules: ModuleTree<RootState> = {
  user: UserStore,
  locality: LocalityStore,
  familyGroup: FamilyGroupStore,
  procedure: ProcedureStore,
  creditCard: CreditCardStore,
  specialty: SpecialtyStore,
  establishment: EstablishmentStore,
  specialist: SpecialistStore,
  exam: ExamStore,
  budget: BudgetStore,
  cart: CartStore,
  consultation: ConsultationStore,
  servicePack: ServicePackStore,
};

export default { state, mutations, actions, getters, modules };

export const store = createStore<any>({ state, mutations, actions, getters, modules });
