import { createRouter, createWebHistory } from "vue-router";

import routes from "@/router/routes";
import { checkAuth, saveCampaignRouteQuery } from "@/router/guard";

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    return to.hash
      ? { el: to.hash, top: window.innerHeight / 8, behavior: "smooth" }
      : to.name !== from.name && !to.params.noScroll
      ? { top: 0, behavior: "smooth" }
      : {};
  },
});

router.beforeEach(saveCampaignRouteQuery);
router.beforeEach(checkAuth);

export default router;
