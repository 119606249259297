import axios, { AxiosError, AxiosResponse } from "axios";
import { ViaCEPError, ViaCEPMessageError } from "@/services/viacep/types";

export const handleViaCEPErros: {
  [key: string]: (error: ViaCEPError) => ViaCEPError;
} = {
  code200(viaCEPError: ViaCEPError) {
    viaCEPError.messages = [{ severity: "error", summary: "CEP invalido!" }];

    return viaCEPError;
  },

  code404(viaCEPError: ViaCEPError) {
    viaCEPError.messages = [{ severity: "error", summary: "Esse recurso não existe!" }];

    return viaCEPError;
  },

  code500(viaCEPError: ViaCEPError) {
    viaCEPError.messages = [
      {
        severity: "error",
        title: "Erro interno",
        summary: "Por favor, tente novamente mais tarde.",
      },
    ];

    return viaCEPError;
  },
};

export async function formatViaCEPError(axiosError: AxiosError | { response: AxiosResponse }) {
  const status = axiosError.response?.status || 500;
  const viaCEPErrorMessages: ViaCEPMessageError[] = [];

  const viaCEPError: ViaCEPError = {
    messages: viaCEPErrorMessages,
    isViaCEPError: true,
    isCancel: axios.isCancel(axiosError),
  };

  const handleViaCEPErro = handleViaCEPErros[`code${status}`];
  if (handleViaCEPErro) return handleViaCEPErro(viaCEPError);

  return viaCEPError;
}

export default formatViaCEPError;
