import obmed, { ObmedCancelRequest } from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { PaginationParams, PaginationResponse, RootState } from "@/store/types";
import {
  LaboratoryExam,
  LaboratoryExamState,
  LaboratoryExamLocaleState,
  LaboratoryExamLocaleMutations,
  LaboratoryExamLocaleActions,
  LaboratoryExamLocaleGetters,
} from "@/store/exam/laboratory/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "consulta-exame-procedimento-cirurgia/?ie_tipo_procedimento=EL&agenda=1",
};

const cancelRequests: { [key: string]: ObmedCancelRequest | null } = {
  search: null,
};

const state: LaboratoryExamLocaleState = {
  list: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 12 },
};

const getters: GetterTree<LaboratoryExamState, RootState> & LaboratoryExamLocaleGetters = {};

const mutations: MutationTree<LaboratoryExamState> & LaboratoryExamLocaleMutations = {
  setLaboratoryExamList(state, laboratories) {
    state.list = laboratories;
  },
  setLaboratoryExamPagination(state, pagination) {
    state.pagination = pagination;
  },
  setLaboratoryExamPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<LaboratoryExamState, RootState> & LaboratoryExamLocaleActions = {
  async searchLaboratoryExam({ state, commit, dispatch }, { search, cd_estabelecimento, isPaginated }) {
    try {
      if (cancelRequests.search) cancelRequests.search.cancel();
      cancelRequests.search = obmed.getCancelToken();

      const pagination: PaginationParams = {
        page: state.pagination.page,
        per_page: state.pagination.perPage,
        paginacao: Boolean(isPaginated),
      };

      const response = await obmed.get<PaginationResponse<LaboratoryExam>>({
        entity: endpoints.root,
        config: {
          query: { ...pagination, nm_procedimento: search, cd_estabelecimento },
          cancelToken: cancelRequests.search?.token,
          baseURL: VUE_APP_API_URL,
        },
      });
      commit("setLaboratoryExamList", response.data.result);
      commit("setLaboratoryExamPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const LaboratoryExamStore = { state, getters, modules, mutations, actions };
