import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";
import { formatDateEn, formatDateTimeEn } from "@/libs/utils";

import { RootState } from "@/store/types";
import {
  User,
  UserState,
  UserLocaleState,
  UserLocaleMutations,
  UserLocaleActions,
  UserForm,
  UserProfileForm,
  UserLocaleGetters,
  ObmedServiceCounter,
  UserPatient,
  RegisterCampaign,
  RegisterCampaignForm,
} from "@/store/user/types";
import { SessionStore } from "./session";
import { AppointmentStore } from "./appointment";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "cadastre-se/",
  signUp: "cadastre-se/",
  byID: (_id: number) => `perfil/${_id}/`,
  userdata: "userdata/",
  forgotPassword: "esqueci-minha-senha/",
  recoverPassword: "esqueci-minha-senha/criar-nova-senha/",
  userContactUs: "fale-conosco/",
  services: "nossos-servicos/",
  campaign: "campanha/",
  campaignByID: (_id: number) => `campanha/${_id}/`,
};

const state: UserLocaleState = {
  current: null,
  registerForm: null,
  serviceCounter: [],
};

const getters: GetterTree<UserState, RootState> & UserLocaleGetters = {
  getObmedServiceCounter(state) {
    return (service) => state.serviceCounter.find((item) => item.servico === service) || null;
  },
};

const mutations: MutationTree<UserState> & UserLocaleMutations = {
  setCurrentUser(state, user) {
    state.current = user;
    if (user) localStorage.setItem("USER_ID", String(user.id));
  },
  setUserRegisterForm(state, form) {
    if (!form) state.registerForm = null;
    else state.registerForm = { ...state.registerForm, ...form };
  },
  setObmedServiceCounter(state, serviceCounter) {
    state.serviceCounter = serviceCounter;
  },
};

const actions: ActionTree<UserState, RootState> & UserLocaleActions = {
  async getUserData({ commit, dispatch }) {
    try {
      const response = await obmed.get<User>({
        entity: endpoints.userdata,
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setCurrentUser", response.data.paciente || null);
      if (response?.data?.id) localStorage.setItem("AUTH_USER_ID", String(response.data.id));

      const cartResponse = await dispatch("getCartList");
      commit("setCartList", cartResponse?.data);

      const userPhone = response.data.paciente?.nr_telefone;
      if (userPhone && !userPhone.includes("+55")) {
        const newUserPhone = userPhone.slice(0, 2) === "55" ? `+${userPhone}` : `+55${userPhone}`;
        dispatch("editUserProfile", { form: { nr_telefone: newUserPhone } });
      }

      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    }
  },
  async getObmedServiceCounter({ commit }) {
    try {
      const response = await obmed.get<ObmedServiceCounter[]>({
        entity: endpoints.services,
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setObmedServiceCounter", response.data || []);

      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    }
  },
  async forgotPassword({ commit, dispatch }, payload) {
    try {
      const response = await obmed.post<any>({
        entity: endpoints.forgotPassword,
        data: payload.form,
        config: {
          baseURL: VUE_APP_API_URL,
          useCompany: false,
          useToken: false,
        },
      });

      if (response?.statusText?.toLowerCase() === "ok")
        commit("setToast", [
          {
            summary: response.data.detail || response.data.info,
            severity: "success",
            life: 10000,
          },
        ]);

      return response;
    } catch (error: any) {
      if (error.isObmedError) commit("setToast", [{ summary: "Usuário não encontrado!", severity: "error" }]);
      else dispatch("handleError", error);
    }
  },
  async recoverPassword({ dispatch }, payload) {
    try {
      const response = await obmed.post<any>({
        entity: endpoints.recoverPassword,
        data: payload.form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false, useToken: false },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async createUser({ state, dispatch }) {
    try {
      if (!state.registerForm) return;

      const form: UserForm = JSON.parse(JSON.stringify(state.registerForm));
      if (!form.dt_nascimento.includes("-")) form.dt_nascimento = formatDateEn(form.dt_nascimento);

      const response = await obmed.post<User, UserForm>({
        entity: endpoints.root,
        data: form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false, useToken: false },
      });

      if ((response?.status || 500) < 300)
        dispatch("updateCampaign", { form: { nm_evento: "CA", cd_usuario: response.data.id } });

      return response;
    } catch (error: any) {
      dispatch("handleError", error);

      return error;
    }
  },
  async editUserProfile({ commit, dispatch }, payload) {
    try {
      const userPhone = payload.form.nr_telefone;
      if (userPhone && !userPhone.includes("+55")) {
        payload.form.nr_telefone = userPhone.slice(0, 2) === "55" ? `+${userPhone}` : `+55${userPhone}`;
      }

      const response = await obmed.patch<UserPatient, Partial<UserProfileForm>>({
        entity: endpoints.byID(Number(localStorage.getItem("USER_ID"))),
        data: payload.form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      commit("setCurrentUser", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async uploadUserProfilePicture({ commit, dispatch }, { form }) {
    try {
      const formData = new FormData();
      formData.append("aq_foto", form.aq_foto);

      const response = await obmed.patch<UserPatient, FormData>({
        entity: endpoints.byID(Number(localStorage.getItem("USER_ID"))),
        data: formData,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      commit("setCurrentUser", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async userContactUs({ dispatch }, payload) {
    try {
      const response = await obmed.post({
        entity: endpoints.userContactUs,
        data: payload.form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async registerCampaign(_, payload) {
    try {
      if (!payload.form.ds_codigo) return;

      const savedCampaign: RegisterCampaign | null = JSON.parse(localStorage.getItem("CAMPAIGN") || "null") || null;
      if (payload.form.ds_codigo === savedCampaign?.ds_codigo) return;

      const response = await obmed.post<RegisterCampaign>({
        entity: endpoints.campaign,
        data: {
          ...payload.form,
          dt_primeiro_acesso: formatDateTimeEn(new Date(), "current"),
          ie_primeiro_acesso: true,
        },
        config: { baseURL: VUE_APP_API_URL, useCompany: false, useToken: false },
      });

      if (response.status === 201) localStorage.setItem("CAMPAIGN", JSON.stringify(response.data));

      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    }
  },
  async updateCampaign(_, { form }) {
    try {
      const savedCampaign: RegisterCampaign | null = JSON.parse(localStorage.getItem("CAMPAIGN") || "null") || null;
      if (!savedCampaign) return;

      const secondaryForm: { [key: string]: any } = {};
      const secondaryFormFields: { [key: string]: { date: string; ie: string } } = {
        LO: { date: "dt_login", ie: "ie_login" },
        CA: { date: "dt_cadastro", ie: "ie_cadastro" },
        RE: { date: "dt_reservou_horario", ie: "ie_reservou_agendamento" },
        PG: { date: "dt_pagou_agendamento", ie: "ie_pagou_agendamento" },
      };
      secondaryForm[secondaryFormFields[form.nm_evento].ie] = true;
      secondaryForm[secondaryFormFields[form.nm_evento].date] = formatDateTimeEn(new Date(), "current");
      secondaryForm.cd_usuario = form.cd_usuario || Number(localStorage.getItem("AUTH_USER_ID")) || null;

      const response = await obmed.patch<RegisterCampaignForm>({
        entity: endpoints.campaignByID(savedCampaign.id),
        data: { ...form, ...secondaryForm },
        config: {
          baseURL: VUE_APP_API_URL,
          useCompany: false,
          useToken: Boolean(localStorage.getItem("AUTH_USER_ID")),
        },
      });

      if (response.status === 200) localStorage.setItem("CAMPAIGN", JSON.stringify(response.data));

      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    }
  },
};

const modules: ModuleTree<RootState> = {
  session: SessionStore,
  appointment: AppointmentStore,
};

export const UserStore = { state, getters, modules, mutations, actions };
