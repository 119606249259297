import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  CartState,
  CartLocaleState,
  CartLocaleMutations,
  CartLocaleActions,
  CartLocaleGetters,
  CartStructure,
  ProcedureCartForm,
  ConsultationCartForm,
  PayCartForm,
  Parcel,
  VoucherResponseT,
} from "@/store/cart/types";
import { compareDates } from "@/libs/utils";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "carrinho-de-compra/",
  consultationByID: (_id: number | string) => `reservar-horario/${_id}/`,
  examByID: (_id: number | string) => `reservar-horario-exame/${_id}/`,
  payment: (_id: number[] | string) => `horario-agendamento/?cd_horario=${_id}`,
  pay: "pagamento/",
  pix: "pagamento-pix/",
  financiamento: "endpoint-de-financiamento-q-ainda-nao-existe/",
  parcel: "simular-parcelas/",
  voucher: "validar-voucher/",
};

const state: CartLocaleState = {
  list: null,
  pix: null,
};

const getters: GetterTree<CartState, RootState> & CartLocaleGetters = {
  validCartList(state) {
    return state.list
      ? {
          ...state.list,
          carrinho: state.list.carrinho.filter((item) =>
            compareDates(item.dt_agenda, new Date().toISOString().split("T")[0])
          ),
        }
      : null;
  },
};

const mutations: MutationTree<CartState> & CartLocaleMutations = {
  setCartList(state, cart) {
    state.list = cart;
  },
  setPix(state, pix) {
    state.pix = pix;
  },
};

const actions: ActionTree<CartState, RootState> & CartLocaleActions = {
  async getCartList({ commit, dispatch }) {
    try {
      const response = await obmed.get<CartStructure>({
        entity: endpoints.root,
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setCartList", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getParcel({ dispatch }, nr_valor: number) {
    try {
      const response = await obmed.get<{ installments: Parcel[] }>({
        entity: endpoints.parcel,
        config: { query: { nr_valor }, baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async addConsultationToCart({ dispatch }, payload) {
    try {
      const response = await obmed.patch<{ cd_checkup: string | null; nm_usuario_edi: string }, ConsultationCartForm>({
        entity: endpoints.consultationByID(payload._id),
        data: { ...payload.form, ie_reservar: "R" },
        config: { baseURL: VUE_APP_API_URL },
      });

      if ((response?.status || 500) < 300) dispatch("updateCampaign", { form: { nm_evento: "RE" } });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async removeConsultationFromCart({ dispatch }, payload) {
    try {
      const response = await obmed.patch<{ cd_checkup: string | null; nm_usuario_edi: string }, ConsultationCartForm>({
        entity: endpoints.consultationByID(payload._id),
        data: { ...payload.form, ie_reservar: "L" },
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async addProcedureToCart({ dispatch }, payload) {
    try {
      const response = await obmed.patch<{ cd_checkup: string | null; nm_usuario_edi: string }, ProcedureCartForm>({
        entity: endpoints.examByID(payload._id),
        data: { ...payload.form, ie_reservar: "R" },
        config: { baseURL: VUE_APP_API_URL },
      });

      if ((response?.status || 500) < 300) dispatch("updateCampaign", { form: { nm_evento: "RE" } });

      return response;
    } catch (error: any) {
      dispatch("handleError", error);

      return error;
    }
  },
  async removeProcedureFromCart({ dispatch }, payload) {
    try {
      const response = await obmed.patch<{ cd_checkup: string | null; nm_usuario_edi: string }, ProcedureCartForm>({
        entity: endpoints.examByID(payload._id),
        data: { ...payload.form, ie_reservar: "L" },
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async payCart({ dispatch }, { form }) {
    try {
      const response = await obmed.post<{ detail?: string; status?: number; body?: any }, PayCartForm>({
        entity: form.forma_pagamento === "credit_card" ? endpoints.pay : endpoints.pix,
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if ((response?.status || 500) < 300 && form.forma_pagamento === "credit_card")
        dispatch("updateCampaign", { form: { nm_evento: "PG" } });

      return response;
    } catch (error: any) {
      if (error?.responseData.status_code === 200) return error.responseData;
      dispatch("handleError", error);
    }
  },
  async consultPayment({ dispatch }, cd_time) {
    try {
      const response = await obmed.get<{ pago: boolean }>({
        entity: endpoints.payment(cd_time),
        config: { baseURL: VUE_APP_API_URL },
      });

      if ((response?.status || 500) < 300 && response.data?.pago)
        dispatch("updateCampaign", { form: { nm_evento: "PG" } });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async checkVoucher({ dispatch }, { codigo, valor_carrinho }) {
    try {
      const response = await obmed.get<VoucherResponseT>({
        entity: endpoints.voucher,
        config: { query: { codigo, valor_carrinho }, baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async handleFinanciamento(_, { form }) {
    try {
      const response = await obmed.post<any, any>({
        entity: endpoints.financiamento,
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error: any) {
      if (error?.responseData.status_code === 200) return error.responseData;
      // dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const CartStore = { state, getters, modules, mutations, actions };
