import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  SpecialtyState,
  SpecialtyLocaleState,
  SpecialtyLocaleMutations,
  SpecialtyLocaleActions,
  SpecialtyLocaleGetters,
  SpecialtyGroupedList,
} from "@/store/specialty/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "consulta-exame-procedimento-cirurgia/?ie_tipo_procedimento=CO",
};

const state: SpecialtyLocaleState = {
  current: null,
  specialtyGroupedList: {},
};

const getters: GetterTree<SpecialtyState, RootState> & SpecialtyLocaleGetters = {};

const mutations: MutationTree<SpecialtyState> & SpecialtyLocaleMutations = {
  setCurrentSpecialty(state, specialty) {
    state.current = specialty;
  },
  setSpecialtyGroupedList(state, specialtyGroupedList) {
    state.specialtyGroupedList = specialtyGroupedList;
  },
};

const actions: ActionTree<SpecialtyState, RootState> & SpecialtyLocaleActions = {
  async getSpecialtyGroupedList({ commit, dispatch }, { nm_especialidade, filters }) {
    try {
      const response = await obmed.get<SpecialtyGroupedList>({
        entity: endpoints.root,
        config: {
          query: { nm_especialidade, ...(filters || {}) },
          baseURL: VUE_APP_API_URL,
        },
      });
      commit("setSpecialtyGroupedList", response.data);

      const specialtyList = Object.keys(response.data).reduce(
        (acumulador: any[], especialidadeId) => [
          { ...(response.data as any)[especialidadeId][0], id: especialidadeId },
          ...acumulador,
        ],
        []
      );

      return { ...response, data: specialtyList };
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const SpecialtyStore = { state, getters, modules, mutations, actions };
