import { RouteRecordRaw } from "vue-router";

const requiresAuthRoutes: RouteRecordRaw[] = [
  // Agendamento
  {
    path: "/agendar/consulta/confirmar/especialista/:cd_especialista/especialidade/:cd_especialidade/agenda/:schedule/estabelecimento/:cd_estabelecimento",
    name: "consultation-confirm",
    meta: { requiresAuth: true },
    component: () => import("@/views/consultation/confirm.vue"),
  },
  {
    path: "/agendar/exame/laboratorial/confirmar",
    name: "exam-laboratory-confirm",
    meta: { requiresAuth: true },
    component: () => import("@/views/exam/confirm.vue"),
  },
  {
    path: "/agendar/exame/imagem/confirmar",
    name: "exam-image-confirm",
    meta: { requiresAuth: true },
    component: () => import("@/views/exam/confirm.vue"),
  },
  {
    path: "/agendar/procedimento/confirmar",
    name: "procedure-confirm",
    meta: { requiresAuth: true },
    component: () => import("@/views/procedure/confirm.vue"),
  },
  {
    path: "/agendar/pacote-servicos/confirmar",
    name: "service-pack-confirm",
    meta: { requiresAuth: true },
    component: () => import("@/views/service-pack/confirm.vue"),
  },
  // Pagamento
  {
    path: "/carrinho",
    name: "cart",
    meta: { requiresAuth: true },
    component: () => import("@/views/cart/cart.vue"),
  },
  {
    path: "/pagar",
    name: "payment",
    meta: { requiresAuth: true },
    component: () => import("@/views/checkout/payment.vue"),
    children: [
      {
        path: "/pagar/adicionar-cartao",
        name: "payment-add-credit-card",
        component: () => import("@/views/credit-card/form.vue"),
      },
    ],
  },
  {
    path: "/pagar/sucesso",
    name: "payment-success",
    meta: { requiresAuth: true },
    component: () => import("@/views/checkout/payment-success.vue"),
  },
  // Dados do usuário
  {
    path: "/perfil",
    name: "profile",
    meta: { requiresAuth: true },
    component: () => import("@/views/profile/profile.vue"),
    redirect: { name: "profile-personal" },
    children: [
      {
        path: "/perfil/dados-pessoais",
        name: "profile-personal",
        component: () => import("@/views/profile/personal.vue"),
      },
      {
        path: "/perfil/login",
        name: "profile-login",
        component: () => import("@/views/profile/login.vue"),
      },
      {
        path: "/perfil/endereco",
        name: "profile-address",
        component: () => import("@/views/profile/address.vue"),
      },
      {
        path: "/perfil/pagamento",
        name: "credit-card",
        component: () => import("@/views/credit-card/credit-card.vue"),
        children: [
          {
            path: "/perfil/pagamento/adicionar",
            name: "credit-card-add",
            component: () => import("@/views/credit-card/form.vue"),
          },
          {
            path: "/perfil/pagamento/:_id/deletar",
            name: "credit-card-delete",
            component: () => import("@/views/credit-card/delete.vue"),
          },
        ],
      },
      {
        path: "/perfil/grupo-familiar",
        name: "family-group",
        component: () => import("@/views/family-group/family-group.vue"),
        children: [
          {
            path: "/perfil/grupo-familiar/adicionar",
            name: "family-group-add",
            component: () => import("@/views/family-group/form.vue"),
          },
          {
            path: "/perfil/grupo-familiar/:_id/editar",
            name: "family-group-edit",
            component: () => import("@/views/family-group/form.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/meus-agendamentos",
    name: "appointment",
    meta: { requiresAuth: true },
    component: () => import("@/views/appointment/appointment.vue"),
    children: [
      {
        path: "/meus-agendamentos/:_id/observacoes",
        name: "appointment-observation",
        beforeEnter(to) {
          if (!Number(to.params._id) || Number(to.params._id) < 1) return { name: "appointment" };
          return true;
        },
        component: () => import("@/views/appointment/observation.vue"),
      },
    ],
  },
  {
    path: "/meus-agendamentos/:_id/reagendar",
    name: "appointment-reschedule",
    meta: { requiresAuth: true },
    component: () => import("@/views/reschedule/reschedule.vue"),
    children: [
      {
        path: "/meus-agendamentos/:_id/reagendar/consulta",
        name: "appointment-reschedule-consultation",
        component: () => import("@/views/reschedule/consultation.vue"),
      },
      {
        path: "/meus-agendamentos/:_id/reagendar/procedimento",
        name: "appointment-reschedule-procedure",
        component: () => import("@/views/reschedule/procedure.vue"),
      },
      {
        path: "/meus-agendamentos/:_id/reagendar/:newSchedule/confirmar",
        name: "appointment-reschedule-confirm",
        component: () => import("@/views/reschedule/confirm.vue"),
      },
    ],
  },
  {
    path: "/financiamento",
    name: "financiamento",
    component: () => import("@/views/financiamento/financiamento.vue"),
  },
];

export { requiresAuthRoutes };
