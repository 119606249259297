import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  CreditCard,
  CreditCardState,
  CreditCardLocaleState,
  CreditCardLocaleMutations,
  CreditCardLocaleActions,
  CreditCardForm,
  CreditCardLocaleGetters,
} from "@/store/credit-card/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "cartao/",
  byID: (_id: number) => `cartao/${_id}/`,
};

const state: CreditCardLocaleState = {
  list: [],
};

const getters: GetterTree<CreditCardState, RootState> & CreditCardLocaleGetters = {};

const mutations: MutationTree<CreditCardState> & CreditCardLocaleMutations = {
  setAllCreditCard(state, creditCard) {
    state.list = creditCard;
  },
};

const actions: ActionTree<CreditCardState, RootState> & CreditCardLocaleActions = {
  async getAllCreditCard({ commit, dispatch }) {
    try {
      const response = await obmed.get<CreditCard[]>({
        entity: endpoints.root,
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setAllCreditCard", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async createCreditCard({ dispatch }, payload) {
    try {
      const response = await obmed.post<CreditCard, CreditCardForm>({
        entity: endpoints.root,
        data: payload.form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async removeCreditCard({ dispatch }, payload) {
    try {
      const response = await obmed.delete<void>({
        entity: endpoints.byID(payload._id),
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const CreditCardStore = { state, getters, modules, mutations, actions };
