import { ActionTree, GetterTree, MutationTree } from "vuex";
import obmed from "@/services/obmed";

import { RootState } from "@/store/types";
import {
  AppointmentActions,
  AppointmentGetters,
  AppointmentMutations,
  AppointmentState,
  Appointment,
} from "@/store/user/appointment/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "historico-de-compra/",
  guia: "imprimir-guia-eletronica/",
  byID: (_id: number) => `agendamento/${_id}/reagendar/`,
};

const state: AppointmentState = {
  list: [],
  rescheduleForm: {
    cd_especialidade: null,
    cd_especialista: null,
    cd_estabelecimento: null,
    cd_procedimento: [],
  },
};

const getters: GetterTree<AppointmentState, RootState> & AppointmentGetters = {};

const mutations: MutationTree<AppointmentState> & AppointmentMutations = {
  setAppointments(state, appointment) {
    state.list = appointment;
  },
  setRescheduleForm(state, form) {
    state.rescheduleForm = { ...state.rescheduleForm, ...form };
  },
};

const actions: ActionTree<AppointmentState, RootState> & AppointmentActions = {
  async getAppointments({ commit, dispatch }) {
    try {
      const response = await obmed.get<Appointment[]>({
        entity: endpoints.root,
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setAppointments", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getElectronicGuide({ dispatch }, payload) {
    try {
      const response = await obmed.get<any>({
        entity: endpoints.guia,
        config: {
          query: { id_guia_eletronica: payload._id },
          baseURL: VUE_APP_API_URL,
        },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async rescheduleAppointment({ dispatch }, { _id, cd_horario_novo }) {
    try {
      const response = await obmed.patch<any>({
        entity: endpoints.byID(_id),
        data: { cd_horario_novo },
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async searchAppointments({ commit, dispatch }, { search }) {
    try {
      const response = await obmed.get<Appointment[]>({
        entity: endpoints.root,
        config: { query: search, baseURL: VUE_APP_API_URL },
      });
      commit("setAppointments", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

export const AppointmentStore = { state, mutations, getters, actions };
