import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";
import { ImageExamStore } from "@/store/exam/image";
import { LaboratoryExamStore } from "@/store/exam/laboratory";

import { RootState } from "@/store/types";
import {
  ExamState,
  ExamLocaleState,
  ExamLocaleMutations,
  ExamLocaleActions,
  ExamLocaleGetters,
} from "@/store/exam/types";

const state: ExamLocaleState = {
  form: {},
};

const getters: GetterTree<ExamState, RootState> & ExamLocaleGetters = {};

const mutations: MutationTree<ExamState> & ExamLocaleMutations = {
  setExamForm(state, { cd_estabelecimento, key, value, procedure, multiples = true }) {
    if (!cd_estabelecimento) return;

    if (!multiples) state.form = {};
    if (!state.form[cd_estabelecimento]) {
      state.form[cd_estabelecimento] = {
        cd_estabelecimento,
        cd_pessoa_fisica: null,
        cd_procedimento: [],
        nm_estabelecimento: null,
        procedures: [],
        nr_valor: null,
        schedule: null,
        scheduleId: null,
      };
    }

    if (key === "cd_procedimento") {
      if (state.form[cd_estabelecimento].cd_procedimento.includes(Number(value)))
        state.form[cd_estabelecimento].cd_procedimento = state.form[cd_estabelecimento].cd_procedimento.filter(
          (item) => item !== Number(value)
        );
      else
        state.form[cd_estabelecimento].cd_procedimento = [
          ...state.form[cd_estabelecimento].cd_procedimento,
          Number(value),
        ];

      if (procedure)
        state.form[cd_estabelecimento].procedures = [...state.form[cd_estabelecimento].procedures, procedure].filter(
          (item) => state.form[cd_estabelecimento].cd_procedimento.includes(item.id)
        );
    } else (state.form[cd_estabelecimento][key] as any) = value?.toString() as any;

    if (!state.form[cd_estabelecimento].cd_procedimento.length) delete state.form[cd_estabelecimento];
  },
  resetExamForm(state) {
    state.form = {};
  },
};

const actions: ActionTree<ExamState, RootState> & ExamLocaleActions = {};

const modules: ModuleTree<RootState> = {
  image: ImageExamStore,
  laboratory: LaboratoryExamStore,
};

export const ExamStore = { state, getters, mutations, actions, modules };
