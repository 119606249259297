import obmed, { ObmedCancelRequest } from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { PaginationParams, PaginationResponse, RootState } from "@/store/types";
import {
  ImageExam,
  ImageExamState,
  ImageExamLocaleState,
  ImageExamLocaleMutations,
  ImageExamLocaleActions,
  ImageExamLocaleGetters,
} from "@/store/exam/image/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "consulta-exame-procedimento-cirurgia/?ie_tipo_procedimento=EC",
  byID: (_id: number) => `consulta-exame-procedimento-cirurgia/${_id}/`,
};

const cancelRequests: { [key: string]: ObmedCancelRequest | null } = {
  search: null,
};

const state: ImageExamLocaleState = {
  list: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 12 },
};

const getters: GetterTree<ImageExamState, RootState> & ImageExamLocaleGetters = {};

const mutations: MutationTree<ImageExamState> & ImageExamLocaleMutations = {
  setImageExamList(state, exam) {
    state.list = exam;
  },
  setImageExamPagination(state, pagination) {
    state.pagination = pagination;
  },
  setImageExamPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<ImageExamState, RootState> & ImageExamLocaleActions = {
  async searchImageExam({ state, commit, dispatch }, { search, cd_estabelecimento, isPaginated }) {
    try {
      if (cancelRequests.search) cancelRequests.search.cancel();
      cancelRequests.search = obmed.getCancelToken();

      const pagination: PaginationParams = {
        page: state.pagination.page,
        per_page: state.pagination.perPage,
        paginacao: Boolean(isPaginated),
      };

      const response = await obmed.get<PaginationResponse<ImageExam>>({
        entity: endpoints.root,
        config: {
          query: { ...pagination, nm_procedimento: search, cd_estabelecimento },
          cancelToken: cancelRequests.search?.token,
          baseURL: VUE_APP_API_URL,
        },
      });
      commit("setImageExamList", response.data.result);
      commit("setImageExamPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const ImageExamStore = { state, getters, mutations, actions, modules };
