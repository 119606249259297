import { RouteRecordRaw } from "vue-router";

import { freeRoutes } from "@/router/routes/free.routes";
import { requiresAuthRoutes } from "@/router/routes/logged.routes";
import { requiresNotLoggedRoutes, requiresNotLoggedModalRoutes } from "@/router/routes/not-logged.routes";

import LDefault from "@/layouts/default.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "root",
    redirect: { name: "home" },
    component: LDefault,
    children: [
      {
        path: "/",
        name: "home",
        alias: "/home",
        component: () => import("@/views/home/home.vue"),
        children: [...requiresNotLoggedModalRoutes],
      },
      ...freeRoutes,
      ...requiresAuthRoutes,
      ...requiresNotLoggedRoutes,
      {
        path: "/:pathMatch(.*)*",
        name: "page-not-found",
        component: () => import("@/layouts/not-found.vue"),
      },
    ],
  },
];

export default routes;
