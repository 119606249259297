import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Establishment,
  EstablishmentState,
  EstablishmentLocaleState,
  EstablishmentLocaleMutations,
  EstablishmentLocaleActions,
  EstablishmentLocaleGetters,
} from "@/store/establishment/types";
import { BudgetByEstablishment } from "@/store/budget/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  byID: (_id: number | string) => `estabelecimento/${_id}/`,
  bySpecialtyID: (_id: number | string) =>
    `estabelecimento-por-especialidade-e-tipo-de-agenda/?cd_especialidade=${_id}&ie_telemedicina=false&ie_tipo_agenda=C`,
  laboratory: "estabelecimento-por-procedimento-e-data-de-agenda/",
  procedure: "estabelecimento-por-tipo-de-agenda-procedimento-e-data/?ie_tipo_agenda=PP",
  servicePack: "estabelecimento-por-tipo-de-agenda-procedimento-e-data/?ie_tipo_agenda=PS",
  exam: "estabelecimento-por-tipo-de-agenda-procedimento-e-data/?ie_tipo_agenda=EC",
  establishment: "estabelecimento-por-tipo-de-agenda-procedimento-e-data/?ie_tipo_agenda=EL",
  budget: "orcamento-agrupado-por-estabelecimento/",
};

const state: EstablishmentLocaleState = {
  current: null,
  list: {},
  listByBudget: {
    disponiveis: {},
    indisponiveis: {},
  },
  listByLaboratory: {},
  listByDate: [],
};

const getters: GetterTree<EstablishmentState, RootState> & EstablishmentLocaleGetters = {};

const mutations: MutationTree<EstablishmentState> & EstablishmentLocaleMutations = {
  setCurrentEstablishment(state, establishment) {
    state.current = establishment;
  },
  setAllEstablishment(state, establishmentList) {
    state.list = establishmentList;
  },
  setAllEstablishmentByLaboratory(state, establishmentList) {
    state.listByLaboratory = establishmentList;
  },
  setAllEstablishmentByDate(state, establishmentList) {
    state.listByDate = establishmentList;
  },
  setAllEstablishmentByBudgetAvailable(state, items) {
    state.listByBudget.disponiveis = items;
  },
  setAllEstablishmentByBudgetUnavailable(state, items) {
    state.listByBudget.indisponiveis = items;
  },
};

const actions: ActionTree<EstablishmentState, RootState> & EstablishmentLocaleActions = {
  async getEstablishment({ commit, dispatch }, { _id, setCurrentEstablishment = true }) {
    try {
      const response = await obmed.get<Establishment>({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      if (setCurrentEstablishment) commit("setCurrentEstablishment", response.data || null);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllEstablishmentBySpecialty({ rootState, commit, dispatch }, payload) {
    try {
      const response = await obmed.get<{ [key: string]: Establishment[] }>({
        entity: endpoints.bySpecialtyID(Number(payload.cd_especialidade || rootState.specialty.current?.id)),
        config: {
          query: {
            cd_especialista: payload.cd_especialista,
            cd_estabelecimento: payload.cd_estabelecimento,
          },
          baseURL: VUE_APP_API_URL,
          useCompany: false,
        },
      });
      commit("setAllEstablishment", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllEstablishmentByLaboratory({ commit, dispatch }, { cd_procedimento, cd_estabelecimento, makeCommit }) {
    try {
      const response = await obmed.get<{ [key: string]: Establishment[] }>({
        entity: endpoints.establishment,
        config: { query: { cd_procedimento, cd_estabelecimento }, baseURL: VUE_APP_API_URL, useCompany: false },
      });
      if (makeCommit !== false) commit("setAllEstablishmentByLaboratory", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllEstablishmentByDate({ commit, dispatch }, payload) {
    try {
      const response = await obmed.get<Establishment[]>({
        entity: endpoints.laboratory,
        config: {
          query: { cd_procedimento: payload.cd_procedimento, dt_agenda: payload.dt_agenda },
          baseURL: VUE_APP_API_URL,
          useCompany: false,
        },
      });
      commit("setAllEstablishmentByDate", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllEstablishmentByBudget({ commit, dispatch }, { cd_procedimentos, cd_especialidades }) {
    try {
      const response = await obmed.get<{
        disponiveis: { [establishmentName: string]: BudgetByEstablishment[] };
        indisponiveis: { [establishmentName: string]: { ds_procedimento: string }[] };
      }>({
        entity: endpoints.budget,
        config: { query: { cd_procedimentos, cd_especialidades }, baseURL: VUE_APP_API_URL },
      });
      commit("setAllEstablishmentByBudgetAvailable", response.data.disponiveis);
      commit("setAllEstablishmentByBudgetUnavailable", response.data.indisponiveis);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllEstablishmentByProcedure({ commit, dispatch }, { cd_procedimento, cd_estabelecimento, makeCommit }) {
    try {
      const response = await obmed.get<{ [key: string]: Establishment[] }>({
        entity: endpoints.procedure,
        config: { query: { cd_procedimento, cd_estabelecimento }, baseURL: VUE_APP_API_URL, useCompany: false },
      });
      if (makeCommit !== false) commit("setAllEstablishment", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllEstablishmentByServicePack({ commit, dispatch }, { cd_procedimento, cd_estabelecimento, makeCommit }) {
    try {
      const response = await obmed.get<{ [key: string]: Establishment[] }>({
        entity: endpoints.servicePack,
        config: { query: { cd_procedimento, cd_estabelecimento }, baseURL: VUE_APP_API_URL, useCompany: false },
      });
      if (makeCommit !== false) commit("setAllEstablishment", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAllEstablishmentByExam({ commit, dispatch }, { cd_procedimento, cd_estabelecimento }) {
    try {
      const response = await obmed.get<{ [key: string]: Establishment[] }>({
        entity: endpoints.exam,
        config: {
          query: { cd_procedimento, cd_estabelecimento },
          baseURL: VUE_APP_API_URL,
          useCompany: false,
        },
      });
      commit("setAllEstablishment", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const EstablishmentStore = { state, getters, modules, mutations, actions };
