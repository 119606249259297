import { ObmedRequestConfig } from "@/services/obmed/types";

export function formatQuery(items: { [key: string]: any }) {
  for (const key in items) {
    const item = items[key];

    if (Array.isArray(item)) {
      items[key] = item.join(",");
    }

    if (!items[key]) {
      delete items[key];
    }
  }

  return items;
}

export function addQuery(config: ObmedRequestConfig = {}) {
  config.params = {
    ...(config.params || {}),
    ...formatQuery(config.query || {}),
  };

  return config;
}

export function configRequestDefs(config: ObmedRequestConfig = {}) {
  config.useCompany = config.useCompany ?? true;
  config.useToken = config.useToken ?? true;
  config.baseURL = config.baseURL ?? "";

  config.headers = config.headers || {};
  const TOKEN = localStorage.getItem("TOKEN");
  if (config.useToken && TOKEN) config.headers.Authorization = `Bearer ${TOKEN}`;

  config.params = config.params || {};

  if (config.useCompany) config.params.cd_empresa = localStorage.getItem("CD_EMPRESA");

  return config;
}
