import obmed, { ObmedCancelRequest } from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { PaginationParams, PaginationResponse, RootState } from "@/store/types";
import {
  Procedure,
  ProcedureState,
  ProcedureLocaleState,
  ProcedureLocaleMutations,
  ProcedureLocaleActions,
  ProcedureLocaleGetters,
} from "@/store/procedure/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "consulta-exame-procedimento-cirurgia/?ie_tipo_procedimento=PP",
  byID: (_id: number | string) => `consulta-exame-procedimento-cirurgia/${_id}/`,
};

const cancelRequests: { [key: string]: ObmedCancelRequest | null } = {
  search: null,
};

const state: ProcedureLocaleState = {
  list: [],
  form: {},
  pagination: { page: 1, total: 1, items: 0, perPage: 12 },
};

const getters: GetterTree<ProcedureState, RootState> & ProcedureLocaleGetters = {};

const mutations: MutationTree<ProcedureState> & ProcedureLocaleMutations = {
  setProcedureForm(state, { cd_estabelecimento, key, value, procedure }) {
    if (!cd_estabelecimento) return;

    if (!state.form[cd_estabelecimento]) {
      state.form = {};
      state.form[cd_estabelecimento] = {
        cd_estabelecimento,
        cd_pessoa_fisica: null,
        cd_procedimento: [],
        nm_estabelecimento: null,
        procedures: [],
        nr_valor: null,
        schedule: null,
        scheduleId: null,
      };
    }

    if (key === "cd_procedimento") {
      state.form[cd_estabelecimento].cd_procedimento = [Number(value)];
      if (procedure) state.form[cd_estabelecimento].procedures = [procedure];
    } else (state.form[cd_estabelecimento][key] as any) = value?.toString() as any;
  },
  resetProcedureForm(state) {
    state.form = {};
  },
  setAllProcedure(state, procedure) {
    state.list = procedure;
  },
  setProcedurePagination(state, pagination) {
    state.pagination = pagination;
  },
  setProcedurePaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<ProcedureState, RootState> & ProcedureLocaleActions = {
  async searchProcedure({ state, commit, dispatch }, { search, cd_estabelecimento, isPaginated }) {
    try {
      if (cancelRequests.search) cancelRequests.search.cancel();
      cancelRequests.search = obmed.getCancelToken();

      const pagination: PaginationParams = {
        page: state.pagination.page,
        per_page: state.pagination.perPage,
        paginacao: Boolean(isPaginated),
      };

      const response = await obmed.get<PaginationResponse<Procedure>>({
        entity: endpoints.root,
        config: {
          query: { ...pagination, nm_procedimento: search, cd_estabelecimento },
          cancelToken: cancelRequests.search?.token,
          baseURL: VUE_APP_API_URL,
        },
      });
      commit("setAllProcedure", response.data.result);
      commit("setProcedurePagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const ProcedureStore = { state, getters, mutations, actions, modules };
