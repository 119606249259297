import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  FamilyGroup,
  FamilyGroupState,
  FamilyGroupLocaleState,
  FamilyGroupLocaleMutations,
  FamilyGroupLocaleActions,
  FamilyGroupForm,
  FamilyGroupLocaleGetters,
} from "@/store/family-group/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "grupo-familiar/",
  byID: (_id: number) => `grupo-familiar/${_id}/`,
};

const state: FamilyGroupLocaleState = {
  current: null,
  list: [],
};

const getters: GetterTree<FamilyGroupState, RootState> & FamilyGroupLocaleGetters = {};

const mutations: MutationTree<FamilyGroupState> & FamilyGroupLocaleMutations = {
  setAllFamilyGroup(state, familyGroup) {
    state.list = familyGroup;
  },
};

const actions: ActionTree<FamilyGroupState, RootState> & FamilyGroupLocaleActions = {
  async getAllFamilyGroup({ commit, dispatch }) {
    try {
      const response = await obmed.get<FamilyGroup[]>({
        entity: endpoints.root,
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setAllFamilyGroup", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async createFamilyGroup({ dispatch }, payload) {
    try {
      const response = await obmed.post<FamilyGroup, FamilyGroupForm>({
        entity: endpoints.root,
        data: payload.form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async editFamilyGroup({ dispatch }, payload) {
    try {
      const response = await obmed.patch<FamilyGroup, Partial<FamilyGroupForm>>({
        entity: endpoints.byID(payload._id),
        data: payload.form,
        config: { baseURL: VUE_APP_API_URL, useCompany: false },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const FamilyGroupStore = { state, getters, modules, mutations, actions };
