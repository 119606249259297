import { RouteRecordRaw } from "vue-router";

const freeRoutes: RouteRecordRaw[] = [
  {
    path: "/agendar/consulta",
    name: "consultation",
    component: () => import("@/views/consultation/consultation.vue"),
    children: [
      // Especialista
      {
        path: "/agendar/consulta/especialista",
        name: "consultation-specialist",
        component: () => import("@/views/consultation/specialist.vue"),
      },
      {
        path: "/agendar/consulta/especialista/:cd_especialista/especialidade/:cd_especialidade/agenda",
        name: "consultation-specialist-schedule",
        component: () => import("@/views/consultation/schedule.vue"),
      },
      {
        path: "/agendar/consulta/especialista/:cd_especialista/especialidade/:cd_especialidade/agenda/:schedule/estabelecimento",
        name: "consultation-specialist-establishment",
        component: () => import("@/views/consultation/establishment.vue"),
      },
      // Especialidade
      {
        path: "/agendar/consulta/especialidade",
        name: "consultation-specialty",
        component: () => import("@/views/consultation/specialty.vue"),
      },
      {
        path: "/agendar/consulta/especialidade/:cd_especialidade/especialista/:cd_especialista/estabelecimento/:cd_estabelecimento/agenda",
        name: "consultation-specialty-schedule",
        component: () => import("@/views/consultation/schedule.vue"),
      },
      // Localidade
      {
        path: "/agendar/consulta/localidade",
        name: "consultation-locality",
        component: () => import("@/views/consultation/specialty.vue"),
      },
    ],
  },
  {
    path: "/agendar/exame",
    name: "exam",
    component: () => import("@/views/exam/exam.vue"),
    children: [
      // Exame de imagem
      {
        path: "/agendar/exame/imagem",
        name: "exam-image",
        component: () => import("@/views/exam/image.vue"),
      },
      // Exame de laboratorial
      {
        path: "/agendar/exame/laboratorial",
        name: "exam-laboratory",
        component: () => import("@/views/exam/laboratory.vue"),
      },
    ],
  },
  {
    path: "/agendar/procedimento",
    name: "procedure",
    component: () => import("@/views/procedure/procedure.vue"),
  },
  {
    path: "/agendar/pacote-servicos",
    name: "service-pack",
    component: () => import("@/views/service-pack/service-pack.vue"),
  },
  {
    path: "/agendar/orcamento",
    name: "budget",
    component: () => import("@/views/budget/budget.vue"),
    children: [
      {
        path: "/agendar/orcamento/estabelecimento",
        name: "budget-establishment",
        component: () => import("@/views/budget/establishment.vue"),
      },
      {
        path: "/agendar/orcamento/:establishment/grupo-familiar",
        name: "budget-family-group",
        props: { viewType: "budget" },
        component: () => import("@/views/family-group/family-group.vue"),
      },
    ],
  },
];

export { freeRoutes };
