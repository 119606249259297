import axios, { AxiosError } from "axios";

import { store } from "@/store";
import { ObmedError, ObmedMessageError } from "@/services/obmed/types";

export const handleObmedErros: {
  [key: string]: (error: ObmedError) => ObmedError;
} = {
  code401(obmedError: ObmedError) {
    store.dispatch("deleteSession");

    return obmedError;
  },

  code404(obmedError: ObmedError) {
    if (!obmedError.messages?.length || !obmedError.messages[0].detail || obmedError.messages.length > 10)
      obmedError.messages = [{ severity: "error", summary: "Esse recurso não existe!" }];

    return obmedError;
  },

  code500(obmedError: ObmedError) {
    return obmedError;
  },
};

export async function formatObmedError(axiosError: AxiosError) {
  const responseData = axiosError.response?.data;
  const obmedErrorMessages: ObmedMessageError[] = [];

  const status = axiosError.response?.status || 500;

  if (status < 500) {
    if (responseData.detail)
      obmedErrorMessages.push({
        summary: "detail",
        detail: String(responseData.detail),
        severity: "error",
      });
    else
      for (const errorName of Object.keys(responseData)) {
        if (Array.isArray(responseData[errorName]))
          responseData[errorName].forEach((errorMessage: any) => {
            obmedErrorMessages.push({
              summary: errorName,
              detail: String(errorMessage),
              severity: "error",
            });
          });
        else
          obmedErrorMessages.push({
            summary: errorName,
            detail: String(responseData[errorName]),
            severity: "error",
          });
      }
  } else if (status >= 500) {
    obmedErrorMessages.push({
      title: "Erro interno",
      summary: "Por favor, tente novamente mais tarde.",
      severity: "error",
    });
  }

  const obmedError: ObmedError = {
    messages: obmedErrorMessages,
    isObmedError: true,
    isCancel: axios.isCancel(axiosError),
    status,
    responseData,
  };

  const handleObmedErro = handleObmedErros[`code${status}`];
  if (handleObmedErro) return handleObmedErro(obmedError);

  return obmedError;
}

export default formatObmedError;
