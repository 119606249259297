import { createApp } from "vue";
import { createHead } from "@vueuse/head";

import { store } from "@/store";
import { router } from "@/router";

import App from "@/app.vue";

import "@/registerServiceWorker";

import "@/libs/styles/main.scss";
import "@/libs/styles/obmed.scss";
import "@/assets/config.scss";
import "bulma-calendar/dist/css/bulma-calendar.min.css";

createApp(App).use(store).use(router).use(createHead()).mount("#app");
