import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from "axios";

import { ObmedParams, ObmedPromise } from "@/services/obmed/types";

import configInterceptors from "@/services/obmed/interceptors";

export type ObmedCancelRequest = CancelTokenSource;

export class ObmedService {
  private http: AxiosInstance;

  constructor(config: AxiosRequestConfig = {}) {
    if (!config.timeout) config.timeout = 40000;
    this.http = configInterceptors(axios.create(config));
  }

  public get<T>(params: ObmedParams): ObmedPromise<T> {
    const response = this.http.get<T, AxiosResponse<T>>(params.entity, params.config);

    return response;
  }

  public post<T, D = any>(params: ObmedParams<D>): ObmedPromise<T> {
    const response = this.http.post<T, AxiosResponse<T>>(params.entity, params.data, params.config);

    return response;
  }

  public patch<T, D = T>(params: ObmedParams<D>): ObmedPromise<T> {
    const response = this.http.patch<T, AxiosResponse<T>>(params.entity, params.data, params.config);

    return response;
  }

  public delete<T>(params: ObmedParams): ObmedPromise<T> {
    const response = this.http.delete<T, AxiosResponse<T>>(params.entity, params.config);

    return response;
  }

  public getCancelToken() {
    return axios.CancelToken.source();
  }
}

export default new ObmedService();
